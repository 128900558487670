import {
    Button,
    HStack,
    Show,
    Text,
    Image,
    Stack,
    Heading,
    NumberField,
    StackDivider,
    Box,
    DateField,
    Input,
    InputGroup,
    InputRightElement,
    Flex,
    Checkbox,
    IconButton,
    Badge,
    Spacer,
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    Th,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from '@pankod/refine-chakra-ui';
import { Card, CardHeader, CardBody } from '@chakra-ui/react'
import { IResourceComponentsProps, useInvalidate, useShow } from "@pankod/refine-core";
import { useEffect, useState } from "react";
import { getAuthenticatedClient, MainSection, useCurrentAdmin, getStatusString, useApiSdk } from 'ui-core';
import { DocumentsBox, PaymentsBox } from 'ui-rto';
import { GqlDocuments } from 'api-client';
import type { AddManualPaymentMutation, AddNoteToOrderMutation, CancelOrderMutation, DeleteOrderNoteMutation, GetOrderHistoryQuery, TransitionOrderToStateMutation } from 'api-client/types';
import { IconTrash } from "@tabler/icons";

const STATUS_PENDING = "PendingApproval";
const STATUS_APPROVED = "Approved";


 interface HistoryItem {
    id: string,
    type: string,
    createdAt: string,
    isPublic: boolean,
    administrator: {
        id: string,
        firstName: string,
        lastName: string,
    },
    data :{
        note: string,
    }
}

export const ShowOrder: React.FC<IResourceComponentsProps> = () => {

    const [postNote, setPostNote] = useState("");
    const [postNotePrivate, setPostNotePrivate] = useState(true);

    const adminQuery = useCurrentAdmin();
    const admin = adminQuery.data?.data;

    const onVarTitleChange = (event: any) => {
        if(event.target.value && event.target.value){
            setPostNote(event.target.value);
            
        }
    }

    const handleOnChnagePostPrivate = () => {
        setPostNotePrivate(!postNotePrivate);
        
    }
    
    const sdk = useApiSdk();

    const onApproveClick = () => {
        
        approveOrder()
    }
    const onCancelClick = () => {
        
        cancelOrder();
    }

    const onCancelRequestClick = () => {
        
        onOpen();
    }

    const invalidate = useInvalidate();
    const { queryResult } = useShow();
    const { data } = queryResult;

    const id = data?.data.id;

    
    let historyItems: HistoryItem[] = [];
    const [orderHistory, setOrderHistory] = useState(historyItems);
    const [orderNotes, setOrderNotes] = useState(historyItems);

    useEffect(() => {
        getOrderHistory(true);
    }, [data]);

    async function cancelOrder() {
        try {
            const result = await sdk.CancelOrder({
                input:{
                    orderId: id as string,
                    reason: "Admin Cancelled",
                    cancelShipping: true,
                    lines: [{
                        orderLineId: data?.data?.lines[0].id as string,
                        quantity: data?.data?.lines[0].quantity as number,
                    }]

                  }  
            });

        } catch(error) {
            console.error(error);
        }

        invalidate({
            resource: "orders",
            invalidates: ["list", "detail"],
            id: id,
        });
    }

    async function approveOrder() {
        try {
            const transitionResult = await sdk.TransitionOrderToState({
                id: id as string,
                state: "Approved"
            });
            
        } catch(error) {
            console.error(error);
        }

        invalidate({
            resource: "orders",
            invalidates: ["list", "detail"],
            id: id,
        });
    }
    
    async function getOrderHistory(isRefresh: boolean) {
        if( id ){
            if(orderHistory.length === 0 || isRefresh) {
                try{
                    const result = await sdk.GetOrderHistory({
                        id: id as string
                    });
                    
                    
                    let noteItems: HistoryItem[] = [];
                    result?.order?.history.items.forEach( (item) => {
                        if(item.type === "ORDER_NOTE") {
                            noteItems.push(item as HistoryItem);
                        }
                    });
                    setOrderHistory(result?.order?.history.items as HistoryItem[]);
                    setOrderNotes(noteItems);

                    
                    return result;
                }catch(error) {
                    console.log(error);
                    return null;
                }
            }
        } else {
            return null;
        }
    }

    async function deleteNote(id: string) {
        const result = await sdk.DeleteOrderNote({
            id: id as string
        })
        getOrderHistory(true);
    }

    async function doPostNote(note:string, isPrivate: boolean) {

        const result = await sdk.AddNoteToOrder({
            input : {
                id: id as string,
                note: note,
                isPublic: isPrivate,
            }
        });
        setPostNote(""); 
        getOrderHistory(true);     
    }
    
    function onPostClick(isCancelationRequest: boolean) {
        if(postNote && postNote.length > 0) {
            if(isCancelationRequest) {
                const message = "Cancelation Request: " + postNote;
                doPostNote(message, true);
                //Todo: trigger notification to RTO office
            }else {
                doPostNote(postNote, postNotePrivate);
            }
            
        }
    }

    function onNoteDelete(id: string) {
        
        deleteNote(id);
    }

    function getHeaderButtons()
    {
        if(data?.data?.state === STATUS_PENDING && admin?.user?.roles[0]?.channels[0]?.code === "__default_channel__") {
            return (
                <HStack> 
                   <Button colorScheme="green" onClick={onApproveClick}>Approve</Button>
                   <Button colorScheme="red" onClick={onCancelClick}>Reject</Button>
               </HStack>
            );
        } else if(data?.data?.state === STATUS_APPROVED) {
            return (
                <HStack>
                    <Button variant="outline" colorScheme="red" onClick={onCancelRequestClick}>Request Cancelation</Button>
                </HStack>
            );
        }

        return null;
    }

    function getDeleteButton(noteItem: HistoryItem) {
        if(admin?.lastName === noteItem?.administrator?.lastName && admin?.firstName === noteItem?.administrator?.firstName ) {
            return (
                <Box p='4'>
                    <IconButton
                        variant='outline'
                        colorScheme='red'
                        aria-label="Delete"
                        icon={<IconTrash/>}
                        size='xs'
                        onClick={ (e) => onNoteDelete(noteItem.id)}
                    />
                </Box>
            );
        }
        return null;
    }

    const { isOpen, onOpen, onClose } = useDisclosure();

return (
    <MainSection variant="form">
        <Show
            title="Contract Details"
           canDelete
           headerButtons={() => ( getHeaderButtons() )}
        >
            
            <Card>
                <CardBody>
                    <Stack divider={<StackDivider />} spacing='4'>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                        Status
                        </Heading>
                        <Box pt='2' fontSize='sm'>
                            {getStatusString(data?.data.state)}
                        </Box>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                        Created
                        </Heading>
                        <Flex pt='2' fontSize='sm'>
                            <DateField value={data?.data.createdAt as string} format="LLL" mr={4} />
                            <Box>by <Box as="span" fontWeight="medium">{data?.data.createdByName}</Box></Box>
                        </Flex>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                        Updated
                        </Heading>
                        <Box pt='2' fontSize='sm'>
                            <DateField value={data?.data.updatedAt as string} format="LLL" />
                        </Box>
                    </Box>
                    </Stack>
                </CardBody>
            </Card>
            <DocumentsBox order={data?.data} />
            <Card>
                <CardHeader>
                    <Heading size='md'>Product</Heading>
                </CardHeader>
                <CardBody>
                    <Image
                        src={data?.data?.lines[0]?.featuredAsset?.preview}
                        alt={data?.data?.lines[0]?.productVariant?.name}
                        borderRadius='lg'
                    />
                    <Stack mt='6' spacing='3'>
                    <Heading size='md'>{data?.data?.lines[0]?.productVariant?.name}</Heading>
                    <Text>
                        
                    </Text>
                    <Box color='blue.600' fontSize='2xl'>
                        <NumberField options={{ style: 'currency', currency: 'USD' }} value={data?.data?.totalWithTax/100} />
                    </Box>
                    </Stack>
                </CardBody>
            </Card>
            <Card>
                <CardHeader>
                    <Heading size='md'>Customer</Heading>
                </CardHeader>

                <CardBody>
                    <Stack divider={<StackDivider />} spacing='4'>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                        Name
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {data?.data.customer?.firstName + " " + data?.data.customer?.lastName}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                        Delivery Address
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {data?.data.shippingAddress.streetLine1}
                            <br/>
                            {data?.data.shippingAddress.city +", " + data?.data.shippingAddress.province + " " + data?.data.shippingAddress.postalCode}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                        Customer Contact Details
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                           Phone# {data?.data.shippingAddress.phoneNumber ? data?.data.shippingAddress.phoneNumber : "NA" }
                        </Text>
                        <Text pt='2' fontSize='sm'>
                           Email: {data?.data.shippingAddress.email ? data?.data.shippingAddress.email: "NA"}
                        </Text>
                    </Box>
                    </Stack>
                </CardBody>
            </Card>
            {data?.data && data?.data?.payments.length && (
                <PaymentsBox order={data?.data} />
            )}
            <Card>
                <CardHeader>
                    <Heading size='md'>Comments</Heading>
                </CardHeader>

                {orderNotes?.map((item: HistoryItem) =>     
                    <Box key={item.id} bg={'white'} borderRadius={'xl'} my={1} p={1} boxShadow={'sm'}>
                        <Flex alignItems={'center'}> 
                            <Text fontWeight={'bold'} mx={3} fontSize={'sm'}>
                                {item?.administrator?.firstName + " " + item?.administrator?.lastName}
                            </Text>
                            
                            <DateField color={"gray"} mx={3} fontSize={'xs'} value={item?.createdAt} format="LLL" />
                            
                            <Badge
                                size='xs'
                                variant='outline'
                                colorScheme={item.isPublic? 'green' : 'yellow'}
                            >
                                {item.isPublic? "Public" : "Admin Only"}
                            </Badge>
                        </Flex>
                        <Flex>
                            <Box p='4'>
                                <Text color={"gray"} fontWeight='semibold' mx ={3} my={1}>
                                    {item?.data?.note}
                                </Text>
                            </Box>
                            <Spacer/>
                            {getDeleteButton(item)}
                        </Flex>
                    </Box>
                        
                )}
                
                <CardBody>
                    <InputGroup size='md'>
                        <Input
                            pr='4.5rem'
                            type='text'
                            placeholder='Enter comment'
                            id="note"
                            onChange={onVarTitleChange}
                            value={postNote}
                        />
                        <InputRightElement width='4.5rem'>
                            <Button color="green" h='1.75rem' size='sm' onClick={ () => {onPostClick(false)}}>
                                Post
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                    <Flex justify="flex-end">
                        <Checkbox
                            size="sm"
                            isChecked={postNotePrivate}
                            onChange={handleOnChnagePostPrivate}
                            id="private"
                            name="private"
                        >
                            Note is Public
                        </Checkbox>
                    </Flex>
                   
                </CardBody>
            </Card>

           
        </Show>
        <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={ () => {
            setPostNote('');
            onClose() }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Cancelation Request</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        
                        <FormControl mt={4} isRequired={true}>
                            <FormLabel>Reason For Cancelation</FormLabel>
                            <Input 
                                 id="note"
                                 onChange={onVarTitleChange}
                                 value={postNote}/>
                        </FormControl>
                       
                        
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={ () => {
                            onPostClick(true);
                            onClose();
                            }}>
                        Submit
                        </Button>
                        <Button onClick={ () => {
                            setPostNote('');
                            onClose() }}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
    </MainSection>
);
};