import { Button, Create, useDisclosure } from "@pankod/refine-chakra-ui";
import { IResourceComponentsProps, useNavigation } from "@pankod/refine-core";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
  } from '@chakra-ui/react'
import { useMemo, useState } from "react";
import { MainSection, TemplateAccordian, useApiSdk, validateTemplateInputs } from "ui-core";
import { useContractSettings } from "ui-rto";
import React from "react";

export const TemplateCreate: React.FC<IResourceComponentsProps>  = () => {
    const [templateTitle, setTemplateTitle] = useState("");
    const [templateState, setTemplateStates] = useState("");
    const [templateCompanies, setTemplateCompanies] = useState("");
    const [templateContents, setTemplateContents] = useState("");
    const [templateVariables, setTemplateVariables] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const { isOpen, onOpen, onClose } = useDisclosure();
    const okRef = React.useRef();

    const settingsDataRef = React.useRef<any>({});
    const settingsQuery = useContractSettings();

    const titleChange = (arg: string) => {
        setTemplateTitle(arg);
    }

    const statesChange = (arg: string) => {
        setTemplateStates(arg);
    }

    const companiesChange = (arg: string) => {
        setTemplateCompanies(arg);
    }

    const contentChange = (arg: string) => {
        setTemplateContents(arg);
    }

    const variablesChange = (arg: string) => {
        setTemplateVariables(arg);
    }

    const navMethods = useNavigation();
    const sdk = useApiSdk();

    async function doCreateTemplate() {  
        try {
            const result = await sdk.CreateContractTemplate ({
                input: {
                    title: templateTitle,
                    states: templateState,
                    companies: templateCompanies,
                    content: templateContents,
                    variables: templateVariables,
                    settings: settingsDataRef.current,
                    productTypes: settingsDataRef.current?.productTypes || [],
                }
            });
            navMethods.list("contractTemplates");
        }catch(error) {
            console.error(error);
            //todo: handle error display
        }
    }
   
    function onSaveCLick() {
        const errorMsg = validateTemplateInputs(templateTitle, templateState, templateCompanies, templateContents, templateVariables);

        if(errorMsg && errorMsg.length > 0) {
            setErrorMessage(errorMsg);
            onOpen();
        } else {
            doCreateTemplate();
        }
    }
    
    return (
        <MainSection variant="form">
            <Create title="Create Template" saveButtonProps={{onClick: () => onSaveCLick()}}>
                <TemplateAccordian 
                    setTitle={titleChange}
                    setStates={statesChange}
                    setCompanies={companiesChange}
                    setVariables={variablesChange}
                    setCotnent={contentChange}
                    settingsDataRef={settingsDataRef}
                />
            </Create>
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={okRef as any}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Required Content
                  </AlertDialogHeader>
      
                  <AlertDialogBody>
                  {errorMessage}
                  </AlertDialogBody>
      
                  <AlertDialogFooter>
                    <Button ref={okRef as any} onClick={onClose}>
                      OK
                    </Button>
                    
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
        </MainSection>
    );
};