import { Box, FormControl, FormErrorMessage, FormLabel, Input, VStack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { ActionBar, RhfPhoneInput, useApiSdk } from 'ui-core';
import {
    useRtoOrderFormData,
    useInvalidateRtoOrderData,
} from '../../../hooks/use-rto-order-form-data';

export const StepWorkInfo = () => {
    const pageQuery = useRtoOrderFormData();
    const invalidate = useInvalidateRtoOrderData();
    const sdk = useApiSdk();
    const order = pageQuery.data?.order;
    const orderFields = order?.rtoContract?.fields;

    const initialValues = {
        occupation: orderFields?.occupation || '',
        employer: orderFields?.employer || '',
        employer_phone: orderFields?.employer_phone || '',
        supervisor: orderFields?.supervisor || '',
    };

    const form = useForm({ defaultValues: initialValues });
    const register = form.register;
    const errors = form.formState.errors;

    const onSubmit = async (formData: any) => {
        const result = await sdk.SetRtoAppFields({
            input: {
                orderId: order?.id || '',
                fields: {
                    ...formData,
                },
            },
        });

        if (result.setRtoAppFields?.id) {
            invalidate(result.setRtoAppFields?.id);
        }
    };

    const handleClickContinue = async () => {
        return form.handleSubmit(onSubmit, () => {
            throw new Error('Invalid form.');
        })();
    };

    return (
        <Box>
            <VStack width="100%" alignItems="start" spacing={10} color="gray.700">
                <VStack alignItems="start" width="100%" spacing={4}>
                    <FormControl isInvalid={!!errors.occupation}>
                        <FormLabel>Occupation</FormLabel>
                        <Input {...register('occupation', { required: 'Required.' })} />
                        <FormErrorMessage>{errors.occupation?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.employer}>
                        <FormLabel>Employer</FormLabel>
                        <Input {...register('employer', { required: 'Required.' })} />
                        <FormErrorMessage>{errors.employer?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.employer_phone}>
                        <FormLabel>Employer phone</FormLabel>
                        <RhfPhoneInput
                            name="employer_phone"
                            control={form.control}
                            rules={{ required: 'Required.' }}
                        />
                        <FormErrorMessage>{errors.employer_phone?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.supervisor}>
                        <FormLabel>Supervisor</FormLabel>
                        <Input {...register('supervisor', { required: 'Required.' })} />
                        <FormErrorMessage>{errors.supervisor?.message}</FormErrorMessage>
                    </FormControl>
                </VStack>
                <ActionBar onSubmit={handleClickContinue} />
            </VStack>
        </Box>
    );
};

export default StepWorkInfo;
