import { useMemo } from 'react';
import { Box, BoxProps, Heading } from '@chakra-ui/react';
import { useRouterContext } from '@pankod/refine-core';

import {
    StepForm,
    StepInterface,
    useStepData,
    useLoadScripts,
    registerScriptResolver,
} from 'ui-core';

import * as Steps from './steps';
import { useRtoOrderFormData } from '../../hooks/use-rto-order-form-data';
import { ContractFinalized } from './contract-finalized';
import { paymentMethods } from './steps/step-payment';

export function getFormSteps(): StepInterface[] {
    return [
        {
            id: 'product',
            label: 'Order Details',
            component: Steps.StepOrderDetails,
        },
        {
            id: 'customer',
            label: 'Customer',
            component: Steps.StepCustomer,
        },
        {
            id: 'term',
            label: 'Rental Term',
            component: Steps.StepTerm,
        },
        {
            id: 'renter-info',
            label: 'Renter Information',
            component: Steps.StepRenterInfo,
        },
        {
            id: 'renter-info',
            label: 'Work Information',
            component: Steps.StepWorkInfo,
        },
        {
            id: 'references',
            label: 'References',
            component: Steps.StepReferences,
        },
        {
            id: 'payment',
            label: 'Payment',
            component: Steps.StepPayment,
        },
        {
            id: 'finalize',
            label: 'Finalize',
            component: Steps.StepFinalize,
        },
    ];
}

const FormStepIndicator = (props: BoxProps) => {
    const stepData = useStepData();
    const currentStep = stepData.steps[stepData.currentStep];

    return (
        <Box mb={8} {...props}>
            <Heading size="lg">{currentStep.label}</Heading>
            <Box>
                Step {stepData.currentStep + 1} of {stepData.steps.length}
            </Box>
        </Box>
    );
};

export const ContractForm = () => {
    const { useLocation } = useRouterContext();
    const location = useLocation();
    const pageQuery = useRtoOrderFormData();
    const order = pageQuery.data?.order;

    const urlParams = new URLSearchParams(location.search);

    const initialFormData = useMemo(() => ({ currentStep: 0, steps: getFormSteps() }), []);
    const finalizedStatuses = ['PendingApproval', 'Approved'];
    const isFinalized =
        finalizedStatuses.indexOf(order?.state || '') >= 0 || urlParams.has('signed');

    useMemo(() => {
        for (let method of paymentMethods) {
            method.onLoadScripts &&
                registerScriptResolver(method.id, method.onLoadScripts, 'payment');
        }
    }, []);

    useLoadScripts('payment', pageQuery.data?.rtoConfig);

    return isFinalized ? (
        <ContractFinalized orderId={order?.id || ''} />
    ) : (
        <StepForm initialData={initialFormData}>
            <FormStepIndicator />
        </StepForm>
    );
};
